import config from '@/config/config.json';
import {i18n, setLocale} from '@/I18n';
import { Env, LangCode, SapLanguage } from '@/models/enums';
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';
import * as consts from "@/models/consts"
import { IsLocalMode } from './ui-utility';
//import { useCurrentApp } from '@/App.vue';
import { getCurrentInstance } from "vue"
import { globals } from '@/main'


//const app = useCurrentApp()
//const isDebug = getCurrentInstance().appContext.config.globalProperties.$isDebug

export const Timeout = (millonSeconds: number) => {
    return new Promise((resolve) => {
        window.setTimeout(resolve, millonSeconds);
    })
};
export function ClearError(state: any) {
    state.errorMsg = "";
}

export function IsDebug() {
    return config.app.env as Env === Env.tch || config.app.env as Env === Env.Dev || config.app.env as Env === Env.integ || globals.$isDebug;
    // return true;
}

export function ConsoleLog(data: any) {
    if (!IsDebug()) {
        return;
    }
    console.log(data);
}
export function validateOdometer(odometer: string) {
    var valid = false;
    try {
        valid = Number.parseFloat(odometer) > 0 && Number.parseFloat(odometer) < 3000000;
        // ConsoleLog({ "numberValue": Number.parseFloat(odometer) })
    } catch (erro) {
    }
    return valid;
}

export function CreateNewUUID() {
    return uuidv4();
}

export function CreateNewUUIDWithoutLine() {
    return uuidv4().replace(/-/g, '');
}

export function IsNullOrEmpty(obj?: string) {
    return obj === undefined || obj === null || obj === '';
}
export function NullToEmpty(obj?: string) {
    return (obj === undefined || obj === null) ? "" : obj;
}
export function CloneObj(obj: any) {
    return obj === undefined ? undefined : JSON.parse(JSON.stringify(obj));
}

export function DeepClone(originalData: any) {
    if (IsNullOrEmpty(originalData)) {
        return originalData;
    }

    return JSON.parse(JSON.stringify(originalData));
}

export function HandleAPIException(data: any, state: any, message: string) {
    if (data && data.isSuccess === false) {
        state.errorMsg = message;
    }
}

export function ChangingConfirm(postObj: any) {
    return RequestConfirmWithEnv(postObj, config.app.env as Env);
}

export function RequestConfirmWithEnv(postObj: any, env: Env) {
    // return true;
    if (env === Env.Prod || !IsLocalMode()) {
        return true;
    }

    const formattedChanging = JSON.stringify(postObj, null, 4);
    CopyToClip(formattedChanging);
    return window.confirm(`
    This message is only for developer debug purpose, user can ignore this and click OK. This popup will be removed after go-live.\r\n
    ${formattedChanging}`);
}

export function CopyToClip(content: string) {
    const aux = document.createElement('input');
    aux.setAttribute('value', content);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand('copy');
    document.body.removeChild(aux);
}

export function ObjectContainAllFields(obj: any, standObj: any, recursive = true) {
    if (!obj || !standObj || typeof obj !== 'object' || typeof standObj !== 'object') {
        ConsoleLog({ "ObjectContainAllFields": `!obj || !standObj || typeof obj !== 'object' || typeof standObj !== 'object'`, obj: obj, standObj: standObj, recursive: recursive });
        return false;
    }
    let isGood = true;
    if (Array.isArray(obj)) {
        // ConsoleLog({ "ObjectContainAllFields": `Array.isArray(obj)`, obj: obj, standObj: standObj, recursive: recursive });
        if (obj.length > 0) {
            //only check the first element if the field is an arry.
            return ObjectContainAllFields(obj[0], Array.isArray(standObj) ? standObj[0] : standObj, recursive);
        } else {
            return true;
        }
    } else {
        // ConsoleLog({ "ObjectContainAllFields": `Note Array`, obj: obj, standObj: standObj, recursive: recursive });
        let shouldSkip = false;
        Object.keys(standObj).forEach(keyName => {
            // ConsoleLog({ "ObjectContainAllFields is checking": `${keyName}`, keyName: obj[keyName], keys: Object.keys(standObj) });
            if (shouldSkip) {
                return;
            }
            if (obj[keyName] === undefined) {
                ConsoleLog({ "ObjectContainAllFields": `${keyName} doesn't exist.`, keyName: obj[keyName] });
                shouldSkip = true;
                isGood = false;
            } else {
                if (typeof obj[keyName] === 'object' && obj[keyName] !== null) {
                    isGood = ObjectContainAllFields(obj[keyName], standObj[keyName], recursive)
                    if (isGood === false) {
                        shouldSkip = true;
                    }
                }
            }
        });
        return isGood;
    }
}

// export function ObjectContainAllFields(obj: any, standObj: any) {
//     let isGood = true;
//     //arry object typeof
//     Object.keys(standObj).forEach(keyName => {
//         if (!obj[keyName]) {
//             ConsoleLog({ keyName: obj[keyName] });
//             isGood = false;
//             return isGood;
//         }
//     });
//     return isGood;
// }

export function ObjectContainAllFieldsForArray(obj: any, standObj: any) {
    let isGood = true;
    if (Array.isArray(obj) && obj.length > 0) {
        isGood = ObjectContainAllFields(obj[0], standObj);
    }
    return isGood;
}

export const ConvertDateTime = (dateTime: string) => {
    let date = ""
    let time = "";
    if (!IsNullOrEmpty(dateTime)) {
        ///Date(1643097360000)/
        try {
            const datetime = new Date(getDateFromAspNetFormat(dateTime));
            date = GetDatePart(datetime);
            //datetime.toISOString().split('T')[0],
            time = datetime.getHours() + ":" + datetime.getMinutes()
        } catch (error) {
            ConsoleLog({ "ConvertDateTime": error });
        }
    }
    return { date: date, time: time };
}

export function getDateFromAspNetFormat(date: string): number {
    const re = /-?\d+/;
    const m = re.exec(date);
    ConsoleLog({ "getDateFromAspNetFormat": m });
    return m && m.length > 0 ? parseInt(m[0], 10) : 0;
}

export const GetDatePart = (date: Date) => {
    let dd = date.getDate().toString();
    let mm = (date.getMonth() + 1).toString();
    var yyyy = date.getFullYear();
    if (parseInt(dd) < 10) {
        dd = '0' + parseInt(dd);
    }
    if (parseInt(mm) < 10) {
        mm = '0' + parseInt(mm);
    }
    return `${yyyy}-${mm}-${dd}`
}
export const GetTimePart = (date: Date) => {
    // return date.getHours() + ":" + date.getMinutes();
    let hourPart = date.getHours().toString();
    let minutePart = date.getMinutes().toString();
    if (parseInt(hourPart) < 10) {
        hourPart = '0' + parseInt(hourPart);
    }
    if (parseInt(minutePart) < 10) {
        minutePart = '0' + parseInt(minutePart);
    }
    return `${hourPart}:${minutePart}`;
}

export const Text = (mLstringId: string, defaultText: string) => {
    const text = TranslatedText(mLstringId);
    return text === mLstringId ? defaultText : text;
};

export const TranslatedText = (mLstringId: string) => {
    // console.log(i18n.global.t(mLstringId));
    // console.log(`utility i18n.global.locale.value: ${i18n.global.locale.value}`);
    // console.log(`utility globals.$lanCode: ${globals.$lanCode}`);
    const language = ConvertBrowserLanguage(window.navigator.language);
    i18n.global.locale.value = language;
    // console.log(`utility i18n.global.locale.value: ${i18n.global.locale.value}`);
    // i18n.global.locale.value = globals.$lanCode;
    // console.log(`page local value: ${i18n.global.locale.value}`);
    return i18n.global.t(mLstringId).toString();
};

export const HasAccess = (userRoles: string[]): boolean => {
    ConsoleLog({ 'HasAccess': DeepClone(userRoles) });
    let hasAccess = false;
    if (userRoles.length === 0) {
        return hasAccess;
    }

    if (userRoles.includes(config.app.role)) {
        hasAccess = true;
    }
    return hasAccess;
};

export const GetSapLanguage = (lang: string): string => {
    ConsoleLog({ 'GetSapLanguage': lang });
    let langCode = SapLanguage.English;
    switch (lang) {
        case LangCode.zh_CN:
            langCode = SapLanguage.Chinese
            break;
        case LangCode.zh_TW:
            langCode = SapLanguage.ChineseZF;
            break;
        case LangCode.id:
        case LangCode.id_ID:
            langCode = SapLanguage.Indonesian;
            break;
        case LangCode.ms_MY:
            langCode = SapLanguage.Malay;
            break;
    }
    // lang.slice(0, 2)
    return langCode;
};


export const ConvertBrowserLanguage = (lang: string): string => {
    // ConsoleLog({ 'ConvertBrowserLanguage': lang });
    let langCode = LangCode.en_US;
    switch (lang.toLowerCase()) {
        case LangCode.zh_CN.toLowerCase():
            langCode = LangCode.zh_CN;
            break;
        case LangCode.zh_TW.toLowerCase():
            langCode = LangCode.zh_TW;
            break;
        case LangCode.id.toLowerCase():
        case LangCode.id_ID.toLowerCase():
            langCode = LangCode.id_ID;
            break;
        case LangCode.ms.toLowerCase():
        case LangCode.ms_MY.toLowerCase():
            langCode = LangCode.ms_MY;
            break;
    }
    // lang.slice(0, 2)
    return langCode;
};

// export const IsUserLogin = () => {
//     const auth = Vue.$authService as any;
//     let isLogin = false;
//     if (auth && auth.getUser()) {
//         const user = auth.getUser();
//         console.log("IsUserLogin", user?.userName, user);
//         isLogin = true;

//     }
//     return isLogin;
// }

export const DateFormatForSAP = (date: string) => {
    let formatedValue = "";
    if (IsNullOrEmpty(date)) {
        return formatedValue
    }
    if (date.length !== 8) {
        return date;
    }
    return date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8)
}
export const TimeFormatForSAP = (time: string) => {
    let formatedValue = "";
    if (IsNullOrEmpty(time)) {
        return formatedValue
    }
    if (time.length !== 6) {
        return time;
    }
    return time.substring(0, 2) + ":" + time.substring(2, 4);
}

export function CountArrayDuplicate (arrary: string[]) {
    return arrary.filter((item, index) => arrary.indexOf(item) != index).length;
}

export function PadLeftZero (aNumber: number) {
    return ("0" + aNumber).slice(-2);
}