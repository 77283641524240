
import { createI18n } from 'vue-i18n'
import axios from 'axios'
import english from '@/locales/en-US.json'
import { nextTick } from 'vue'

const defaultLang = 'en-US', defaultMessages = { 'en-US': english }

const numberFormatsTemplate = {
  '2dec': { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  '3dec': { minimumFractionDigits: 3, maximumFractionDigits: 3 },
  '4dec': { minimumFractionDigits: 4, maximumFractionDigits: 4 }
}

const dateTimeFormatsTemplate = {
  short: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  },
  med: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
  },
  long: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric'
  }
}

const numberFormats = { 'en-US': numberFormatsTemplate } as any,// eslint-disable-line
  datetimeFormats = { 'en-US': dateTimeFormatsTemplate } as any;// eslint-disable-line

// Set new locale.
export async function setLocale(locale: string) {
  console.log(`setLocale started`)
  
  if (!i18n.global.availableLocales.includes(locale)) {
    return import(/* webpackChunkName: "lang-[request]" */ `./locales/${locale}`)
      .then(msgs => {
        return intialLanguage(locale, msgs);
      })
      .catch(() => {
        return intialLanguage(defaultLang, defaultMessages['en-US']);
      })
  }
  
  // if (!i18n.global.availableLocales.includes(locale)) {
  //   const messages = await import(/* webpackChunkName: "lang-[request]" */ `./locales/${locale}`)
  //   i18n.global.setLocaleMessage(locale, messages.default)
  //   return nextTick()
  // }
}

const intialLanguage = (lang: string, messages: any) => { // eslint-disable-line
  numberFormats[lang] = numberFormatsTemplate
  datetimeFormats[lang] = dateTimeFormatsTemplate
  i18n.global.setLocaleMessage(lang, messages);
  i18n.global.locale.value = lang;
  return setI18nLanguage(lang)
}

const setI18nLanguage = (lang: string) => {
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html')?.setAttribute('lang', lang)
  console.log(`setLocale completed`)
  // return lang
  return nextTick()
}

export const i18n = createI18n({
  globalInjection: true,
  legacy: false, //default is true
  locale: defaultLang,
  fallbackLocale: defaultLang,
  allowComposition: true,
  datetimeFormats,
  numberFormats,
  messages: defaultMessages,
  missingWarn: false, // Composition  [intlify] Not found 'ml_107448' key in 'en' locale messages.
})

export function setI18nLanguageV2(i18n, locale) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  axios.defaults.headers.common['Accept-Language'] = locale
  document.querySelector('html')?.setAttribute('lang', locale)
}

export async function loadLocaleMessages(i18n, locale) {
  // load locale messages with dynamic import
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
  )

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default)

  return nextTick()
}
