import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { PageName } from '@/models/enums';
import { authService } from '@/services/AuthorService';
import { ConsoleLog, IsNullOrEmpty, ConvertBrowserLanguage } from '@/utility';
import config from '@/config/config.json';
import HomeEntry from '../views/HomeEntry.vue';
import SearchPage from '../views/SearchPage.vue';
import TripInformation from '../views/TripInformation.vue';
import VehicleCondition from '../views/VehicleCondition.vue';
import RecordOverview from '../views/RecordOverview.vue';
import RecordQuestionDetail from '../views/RecordQuestionDetail.vue';
import RecordQuestionDefect from '../views/RecordQuestionDefect.vue';
import RecordQuestionGood from '../views/RecordQuestionGood.vue';
import RecordQuestionDefectSummary from '../views/RecordQuestionDefectSummary.vue';
import RecordQuestionSubmitNotice from '../views/RecordQuestionSubmitNotice.vue';
import RecordQuestionSubmitSign from '../views/RecordQuestionSubmitSign.vue';
import RecordQuestionSubmitResult from '../views/RecordQuestionSubmitResult.vue';
import TripInformationView from '../views/TripInformationView.vue';
import About from '../views/About.vue';
import QueSection from '../views/QueSection.vue';
import { Vehicle } from '@/models/vehicle';
import {i18n, loadLocaleMessages, setI18nLanguageV2, setLocale} from "../I18n";
import { globals } from "@/main";

const routes: Array<RouteRecordRaw> = [
  {
      path: '/',
      name: PageName.HomePage,
      component: HomeEntry
  },
  {
      path: '/search-trip',
      name: PageName.SearchPage,
      component: SearchPage,
      meta: {
          isSecure: true,
        }
  },
  {
      path: '/trip-information',
      name: PageName.TripInformation,
      component: TripInformation,
      meta: {
          isSecure: true,
        }
  },
  {
      path: '/vehicle-condition',
      name: PageName.VehicleCondition,
      component: VehicleCondition,
      meta: {
          isSecure: true,
        }
  },
  {
      path: '/record-overview',
      name: PageName.RecordOverview,
      component: RecordOverview,
      meta: {
          isSecure: true,
        }
  },
  {
      path: '/record-question/:subSectionId',
      name: PageName.QueSection,
      component: QueSection,
      props: true,
      meta: {
          isSecure: true,
        }
  },

  {
      path: '/record-question-detail',
      name: PageName.RecordQuestionDetail,
      component: RecordQuestionDetail,
      meta: {
          isSecure: true,
        }
  },
  {
      path: '/record-question-defect',
      name: PageName.RecordQuestionDefect,
      component: RecordQuestionDefect,
      props: true,
      meta: {
          isSecure: true,
        }
  },
  {
      path: '/record-question-good',
      name: PageName.RecordQuestionGood,
      component: RecordQuestionGood,
      props: true,
      meta: {
          isSecure: true,
        }
  },
  {
      path: '/record-question-defect-summary',
      name: PageName.RecordQuestionDefectSummary,
      component: RecordQuestionDefectSummary,
      props: true,
      meta: {
          isSecure: true,
        }
  },
  {
      path: '/record-question-submit-notice',
      name: PageName.RecordQuestionSubmitNotice,
      component: RecordQuestionSubmitNotice,
      meta: {
          isSecure: true,
        }

  },
  {
      path: '/record-question-submit-sign',
      name: PageName.RecordQuestionSubmitSign,
      component: RecordQuestionSubmitSign,
      meta: {
          isSecure: true,
        }

  },
  {
      path: '/record-question-submit-result/:action',
      name: PageName.RecordQuestionSubmitResult,
      component: RecordQuestionSubmitResult,
      props: true,
      meta: {
          isSecure: true,
        }
  },
  {
      path: '/trip-information-view',
      name: PageName.TripInformationView,
      component: TripInformationView,
      props: true,
      meta: {
          isSecure: true,
        }
  },
  {
      path: '/about',
      name: PageName.About,
      component: About,
  },
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  // ConsoleLog(`beforeEach ${to.params.locale}`);
  ConsoleLog(`beforeEach ${globals.$lanCode}`);
  const language = ConvertBrowserLanguage(window.navigator.language);
  ConsoleLog({
    "beforeEach window.navigator.language": window.navigator.language,
    language: language,
  });
  globals.$lanCode = language

  const paramsLocale = language

  // load locale messages
  if (!i18n.global.availableLocales.includes(paramsLocale)) {
    await loadLocaleMessages(i18n, paramsLocale)
  }

  // set i18n language
  setI18nLanguageV2(i18n, paramsLocale)

  // setLocale(language);

  ConsoleLog(`beforeEach ${globals.$lanCode}`);

  ConsoleLog({ "router.beforeEach": to, "from": from, 'next': next });
  if (to.matched.some((record) => record.meta.isSecure)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    ConsoleLog("router.beforeEach.authService.isLogin()");
    console.log(`router.beforeEach check isSecure`)
    const isLoggedIn = await authService.isLogin()
    if (isLoggedIn) {
      if (from.name === null && to.name !== PageName.HomePage) {
        // ConsoleLog("router.beforeEach.from.name === null && to.name !== PageName.HomePage");
        next({ path: '/' })
      } else {
        next();
      }
    } else {
      // ConsoleLog({ "router.beforeEach.notLogin": to, "from": from, 'next': next });
      // next({ path: '/' })
      ConsoleLog(`not log in, redirect to` + `${config.app.fuelHome}`);
      window.open(config.app.fuelHome, "_self");
    }
  } 
  else {
    next();
  }
  //   authService.isLogin().then((isLoggedIn: boolean) => {
  //     if (isLoggedIn) {
  //       if (from.name === null && to.name !== PageName.HomePage) {
  //         // ConsoleLog("router.beforeEach.from.name === null && to.name !== PageName.HomePage");
  //         next({ path: '/' })
  //       } else {
  //         next();
  //       }
  //     } else {
  //       // ConsoleLog({ "router.beforeEach.notLogin": to, "from": from, 'next': next });
  //       // next({ path: '/' })
  //       ConsoleLog(`not log in, redirect to` + `${config.app.fuelHome}`);
  //       window.open(config.app.fuelHome, "_self");
  //     }
  //   });
  // } else {
  //   next();
  // }

});

export default router