<template>
  <div id="app">
    <div v-if="this.$store.state.isLoading" id="ap-loading-overlay">
      <div id="ap-loading-image"></div>
    </div>
    <v-app>
      <v-main>
        <v-container fluid>
          <router-view :key="$route.fullPath" />
          <div id="ap-footer">
            <div v-if="loginError.length > 0" class="red-error">
              {{ loginError }}
            </div>
            <div id="ap-copyright">
              Copyright &copy; 1996 - {{ new Date().getFullYear() }} Air
              Products and Chemicals, Inc {{ version }}
            </div>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script lang="ts">
import { version } from 'vue'
import { useI18n } from 'vue-i18n'
import Vue from "vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import {i18n, setLocale} from "./I18n";
import {
  CloneObj,
  ConsoleLog,
  ConvertBrowserLanguage,
  DeepClone,
} from "./utility";
import { mapGetters } from "vuex";
import { StoreAction, StoreGetter, StoreMutation } from "./store/store-mapping";
import config from "@/config/config.json";
import AuthService from "./services/AuthorService";
import APIService, { apiService } from "./services/APIService";
import { getCurrentInstance } from "vue"
import { globals } from '@/main'



import { defineComponent } from 'vue';
export default defineComponent( {
  name: "App",
  components: {
    loadingOverlay: LoadingOverlay,
  },
  data: () => ({
    version: config.app.version,
    isLoggedIn: false,
    loginError: "",
  }),
  computed: {
    ...mapGetters({
      userProfile: StoreGetter.umUserProfile,
    }),
  },
  methods: {
    setIsDebug() {
      const search = window.location.search;
      if (search.length === 0) {
        return;
      }
      const query = search.substring(1);
      const parameters = query.split("&");
      for (const item of parameters) {
        const pair = item.split("=");
        if (
          pair.length === 2 &&
          pair[0].toLocaleLowerCase() === "debug" &&
          pair[1].toLocaleLowerCase() === "true"
        ) {
          console.log("Vue.prototype.$isDebug = true;");
          globals.$isDebug = true;
        }
      }
    },
    async initialLan() {
      const language = ConvertBrowserLanguage(window.navigator.language);
      ConsoleLog({
        "window.navigator.language": window.navigator.language,
        language: language,
      });
      globals.$lanCode = language;
      await setLocale(language);
      
      // $i18n.loadLanguageAsync(language);
      // i18n.loadLanguageAsync(language);
      // (i18n as any).loadLanguageAsync(language);
      // self.$i18n.loadLanguageAsync(language);
    },
  },
  // watch: {
  //   // watch $route decide which style to use
  //   $route(to, from) {
  //     (this as any).transitionName = GenerateTransitionName(to, from);
  //   },
  // },
  async beforeMount() {
    // ConsoleLog({ "App.beforeMount": "a" });

    //Logging
    console.log(globals.$myProp)
    await this.$store.dispatch(StoreAction.umLogin);
    if (!this.userProfile) {
      this.isLoggedIn = false;
      return;
    }
    this.isLoggedIn = true;
    ConsoleLog({ "App.userProfile": DeepClone(this.userProfile) });
    // await this.initialLan();

    // await this.checkLogin();
    // self.$store.commit(StoreMutation.EndLoading);

    // if (self.userProfile) {
    //   ConsoleLog({ "userProfile.LanguageCode": self.userProfile.LanguageCode });
    //   Vue.prototype.$lanCode = self.userProfile.LanguageCode;
    //   await self.$i18n.loadLanguageAsync(self.userProfile.LanguageCode);
    //   self.$store.commit("EndLoading");
    // } else {
    //   await self.$i18n.loadLanguageAsync("zh-CN");
    // }
  },
  async mounted() {
    ConsoleLog({
      "app.mounted.window.navigator.language": window.navigator.language,
    });
    ConsoleLog({"vueJs version:":version});
    await this.initialLan();
  },
  async created() {
    (this as any).setIsDebug();
    // ConsoleLog({
    //   "App.created": "a",
    // });
  },
  beforeCreate() {
    // ConsoleLog({
    //   "App.beforeCreate": "a",
    // });
  },
});
</script>

<style>
@import "./assets/site.css";
</style>
